.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

@media screen and (max-width: 600px) {
    .display-2 {
      font-size: 3rem;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

.h1,
h1 {
    font-size: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.pl-3,
.px-3 {
    padding-left: 0.5rem !important;
    padding-bottom: 0.25rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.25rem !important;
}
.float-right {
    float: right !important;
}

.bg-gray {
    background-color: #adb5bd;
    color: #1f2d3d;
}

.h5,
h5 {
    font-size: 1.25rem;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-right: auto;
    margin-left: auto;
}

.content-header {
    padding: 15px 0.5rem;
}

@media (min-width: 576px) {
    .float-sm-right {
        float: right !important;
    }
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --purple-light: #8a61d5;
    --primary: #8a61d5;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

body {
    margin: 0;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.bg-white {
    background-color: #fff;
    color: #1f2d3d !important;
}

.bg-blue,
.bg-blue > a {
    color: #fff !important;
}
.bg-blue {
    background-color: #007bff !important;
}

.bg-red,
.bg-red > a {
    color: #fff !important;
}
.bg-red {
    background-color: #dc3545 !important;
}

.bg-orange,
.bg-orange > a {
    color: #1f2d3d !important;
}
.bg-orange {
     /* background-color: #fd7e14 !important; */
    background-color: #FF7F27 !important;
}

.bg-green,
.bg-green > a {
    color: #fff !important;
}
.bg-green {
    background-color: #28a745 !important;
}

.bg-black {
    background-color: #000;
    color: #fff !important;
}
.bg-gold,
.bg-gold > a {
    color: #fff !important;
}
.bg-gold {
    background-color: #FFD700 !important;
}

.bg-conductive,
.bg-conductive > a {
    color: #fff !important;
}
.bg-conductive {
    background-color: #C0C0C0 !important;
}

.ribbon-wrapper {
    height: 70px;
    overflow: hidden;
    position: absolute;
    right: -2px;
    top: -2px;
    width: 70px;
    z-index: 10;
}

.bg-success,
.bg-success > a {
    color: #fff !important;
}
.bg-success {
    background-color: #28a745 !important;
}

.ribbon-wrapper .ribbon {
    box-shadow: 0 0 3px rgba(0, 0, 0, 1 / 30%);
    font-size: 0.8rem;
    line-height: 100%;
    padding: 0.375rem 0;
    position: relative;
    right: -2px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 1 / 40%);
    text-transform: uppercase;
    top: 10px;
    transform: rotate(45deg);
    width: 90px;
}

.bg-primary,
.bg-primary > a {
    color: #fff !important;
}
.bg-primary {
    background-color: #8a61d5 !important;
}
.font-weight-light {
    font-weight: 300 !important;
}

.timeline::before {
    border-radius: 0.25rem;
    background-color: #dee2e6;
    bottom: 0;
    content: "";
    left: 31px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 4px;
}

.timeline > div {
    margin-bottom: 15px;
    margin-right: 10px;
    position: relative;
}

.position-relative {
    position: relative !important;
}

img {
    vertical-align: middle;
    border-style: none;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}
.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.list-group-unbordered > .list-group-item {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.bg-purpledark, .bg-purpledark > a {
    color: #fff !important;
}
.bg-purpledark {
    background-color: #4a485d !important;
}

.justify-content-center {
    justify-content: center !important;
}
.d-flex {
    display: flex !important;
}

.text-orange {
    color: #fd7e14 !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 700;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}
.mt-3, .my-3 {
    margin-top: 1rem !important;
}

.mr-2, .mx-2 {
    margin-right: 0.5rem !important;
}

a:hover {
    text-decoration: none;
}

a {
    text-decoration: none;
    background-color: transparent;
}

.text-center{
    text-align: center;
}

.Orbitron20 {
    font-family: Orbitron-Variable;
    font-size: 20px;
    letter-spacing: 1.5px;
  }
  
  .Orbitron18 {
    font-family: Orbitron-Variable;
    font-size: 18px;
    letter-spacing: 1.5px;
  }
  
  .Orbitron16 {
    font-family: Orbitron-Variable;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .Orbitron15 {
    font-family: Orbitron-Variable;
    font-size: 15px;
    letter-spacing: 1px;
  }
  
  .Orbitron14 {
    font-family: Orbitron-Variable;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  .width150 {
    width: 200px;
    height: auto;
  }
  
  .colorBox {
    width: 22px;
    height: 22px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border: 1px solid rgb(145, 145, 145);
    text-align: center;
    display: inline-block;
  }
  
  .inkQtyBox {
    width: 40px;
    height: auto;
    margin: 2px;
  
    text-align: center;
    display: inline-block;
  }
  
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .printer-header {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75re 1rem;
    margin-bottom: 0;
    list-style: none;
    border-radius: 0.25rem;
  }
  