.buttons-container{
    display: table-cell;
    width: 500px;
    height: 500px;
    vertical-align: middle;
    text-align: center;
}

.login-button{
    margin: 0 auto;
    display: block;
    width: 300px;
}

.center-paragraph{
    text-align: center;
    margin-top: 15px;
}

.full-width{
    width: 100%;
}