.MainPurpure {
  color: #4b485d;
  font-weight: 400;
}

.bg-purple {
  border: 2px solid #8a61d5;
}

.bg-green {
  border: 2px solid #239729;
}

.bg-orange {
  border: 2px solid #ed6c02;
}

.bg-red {
  border: 2px solid #dc3545;
}

.bg-grey {
  border: 2px solid #d6d4d4;
}

