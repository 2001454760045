.graph-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
}

.graph-row{
    margin-right: 10px !important;
}

.chart-length{
    border-bottom: 1px solid black !important;
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
}

.chart-head{
    border-top: 1px solid black !important;
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
}

#column-example-6 {
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
}
.circle {
    width: 80px;
    height: 80px;
    border: 1px solid #000;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circleWhite {
    width: 80px;
    height: 80px;
    border: 1px solid white;
    border-radius: 50%;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text {
    color: black;
    font-size: 20px;
    font-weight: 600;
}
.textWhite {
    color: white;
    font-size: 20px;
    font-weight: 600;
}
#my-chart.column {
    --labels-size: 4rem;
}
#my-chart.bar {
    --labels-size: 200px;
}
