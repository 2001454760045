.custom-news-image img{
    max-width: 540px;
    width: 812px;
    height: 500px;
}

.custom-news-header{
    font-size: 20px;
    width: 100%;
    height: 50px;
}

.custom-news-description{
    font-size: 15px;
    width: 100%;
    height: 80px;
}