.lightTextEditor .EasyMDEContainer {
    .editor-toolbar {
        background-color: white;
    }
}

.darkTextEditor .EasyMDEContainer {
    .editor-toolbar {
        background-color: #252929;
        button {
            &.active {
                color: black;
            }
            color: white;
        }
        button:hover {
            color: black;
        }
    }
    .CodeMirror {
        background-color: #252929;
        * {
            color: white;
        }
        .CodeMirror-scroll{
            .CodeMirror-sizer{
                div{
                    .CodeMirror-lines{
                        div{
                            .CodeMirror-cursors {
                                .CodeMirror-cursor{
                                    border-left: 1px solid white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .editor-preview {
        background-color: #252929;
    }
}
