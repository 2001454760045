@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");
@import "/src/style/css/customGlobalStyles.css";
@import "/src/style/css/Print.css";
@import "~react-toastify/scss/main";

.main-header .navbar-nav .nav-item .nav-link {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.navbar-nav > .user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

iframe {
    display: none !important;
}

.noBackgroundImage {
    background-image: none !important;
}


html,body,#root{
    height: 100%;
}
