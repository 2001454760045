@media print {
    .print-paragraph {
        display: block;
    }
}

.print-paragraph {
    display: none;
}

.print-name-div {
    text-align: center;
    background-color: transparent;
    font-size: 24px;
}

.width-90-px {
    width: 90px;
}

.margin-left-15-px {
    margin-left: 15px;
}

.margin-right-15-px {
    margin-right: 15px;
}

.print-chip {
    margin-left: 2px;
    margin-right: 2px;
}

.job-image {
    max-height: 400px;
    max-width: 400px;
    border: 1px solid black;
}

.job-image-printer {
    max-height: 300px;
    max-width: 300px;
    margin-left: 70px;
    border: 1px solid black;
}

.padding-0 {
    padding: 0;
}

.padding-25-px {
    padding: 25px;
}