.custom-text-field {
    div {
        input {
            padding: 6px 5px;
        }
        fieldset {
            border: 1px solid rgba(0, 0, 0, 0.23) !important;
            legend {
                width: initial;
            }
        }
    }
}

.custom-text-field-like-li {
    div:first-child {
        &::after {
            border-bottom: none;
        }
        &::before {
            border-bottom: none;
        }
    }
}
