.light-file-button{

}

.dark-file-button{

}

.add-job-from-label{
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.custom-file-input-filed{
    .MuiOutlinedInput-root{
        input{
            padding: 6.5px 14px;
        }
    }
    width: 100%;
}