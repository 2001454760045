@font-face {
  font-family: Orbitron-Variable;
  src: url(../../../style/fonts/Orbitron-VariableFont_wght.ttf);
}

.Orbitron20 {
  font-family: Orbitron-Variable;
  font-size: 20px;
  letter-spacing: 1.5px;
}

.Orbitron18 {
  font-family: Orbitron-Variable;
  font-size: 18px;
  letter-spacing: 1.5px;
}

.Orbitron16 {
  font-family: Orbitron-Variable;
  font-size: 16px;
  letter-spacing: 1px;
}

.Orbitron15 {
  font-family: Orbitron-Variable;
  font-size: 15px;
  letter-spacing: 1px;
}

.Orbitron14 {
  font-family: Orbitron-Variable;
  font-size: 14px;
  letter-spacing: 1px;
}

.width150 {
  width: 200px;
  height: auto;
}

.colorBox {
  width: 22px;
  height: 22px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid rgb(145, 145, 145);
  text-align: center;
  display: inline-block;
}

.inkQtyBox {
  width: 40px;
  height: auto;
  margin: 2px;

  text-align: center;
  display: inline-block;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.printer-header {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75re 1rem;
  margin-bottom: 0;
  list-style: none;
  border-radius: 0.25rem;
}
