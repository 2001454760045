.body-auth {
    height: 100vh;
    width: 100%;
    /* background: linear-gradient(330deg, rgba(110,107,156,1) 0%, rgba(138,97,213,1) 50%, rgb(119 90 171) 100%); */
}
.show-btn {
    background: #fff;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    color: #3498db;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-text {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}
.text-stroke {
    color: white;
    -webkit-text-stroke: 1px black;
}

form .data label {
    font-size: 18px;
}
form .data input {
    height: 100%;
    width: 100%;
    padding-left: 10px;
    font-size: 17px;
    border: 1px solid silver;
}
form .data input:focus {
    border-color: #3498db;
    border-bottom-width: 2px;
}
form .forgot-pass {
    margin-top: -8px;
}
form .forgot-pass a {
    color: #3498db;
    text-decoration: none;
}
form .forgot-pass a:hover {
    text-decoration: underline;
}
form .btn {
    margin: 10px 0;
    height: 45px;
    width: 100%;
    position: relative;
    overflow: hidden;
}
form .btn .inner {
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    z-index: -1;
    background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
    transition: all 0.4s;
}
form .btn button {
    height: 100%;
    width: 100%;
    background: none;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
}
