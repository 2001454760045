.history-icon {
    background-color: #adb5bd;
    border-radius: 50%;
    font-size: 16px;
    height: 30px;
    left: 18px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: 45px;
    width: 30px;
}

.history-body {
    padding: 10px;
}

.history-title {
    font-size: 16px;
    line-height: 1.1;
    margin: 0;
    padding: 10px;
    border-bottom-color: #dee2e6;
}

.history-header{
    border-bottom: 1px solid rgba(0,0,0,.125);

}

.history-time {
    color: #999;
    float: right;
    font-size: 12px;
    padding: 10px;
}

.history-date {
    border-radius: 4px;
    display: inline-block;
    padding: 5px;
}

.history-card {
    box-shadow: none;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    color: #495057;
    margin-left: 60px;
    margin-top: 0;
    padding: 0;
    position: relative;
}
