.card-chart {
  overflow: hidden;
}
.card-chart .card-header .card-title i {
  font-size: 16px;
  margin-right: 5px;
  margin-bottom: 3px;
}
.card-chart .card-header .card-category {
  margin-bottom: 5px;
}
.card-chart .card-body {
  padding-left: 5px;
  padding-right: 5px;
}
.card-chart .card-body .tab-space {
  padding: 0;
}
.card-chart .table {
  margin-bottom: 0;
}
.card-chart .table td {
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.card-chart .card-progress {
  margin-top: 30px;
  padding: 0 10px;
}
.card-chart .chart-area {
  height: 220px;
  width: 100%;
}
.card-chart .card-footer {
  margin-top: 15px;
}
.card-chart .card-footer .stats {
  color: #9a9a9a;
}
.card-chart .dropdown {
  position: absolute;
  right: 20px;
  top: 20px;
}
.card-chart .dropdown .btn {
  margin: 0;
}
.card-chart.card-chart-pie .chart-area {
  padding: 10px 0 25px;
  height: auto;
}
.card-chart.card-chart-pie .card-title {
  margin-bottom: 10px;
}
.card-chart.card-chart-pie .card-title i {
  font-size: 1rem;
}

.required:after {
  content:" *";
  color: red;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

