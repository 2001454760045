.MainPurpure {
  color: #4b485d;
}

.weight400{
  font-weight: 400;
}

.bg-Yellow {
  background-color: #ffd365;
  color: #232323;
}

.bg-Red {
  background-color: #dc3545;
  color: #232323;
}
