@function get-vw($target) { 
  $vw-context: (1000*.01) * 1px;
  @return ($target/$vw-context) * 1vw;
}

$window-width: get-vw(72px);

.color-ball-grid{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

.color-ball {
  position: absolute;
  inset: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset;
  background: rgb(114, 161, 138) none repeat scroll 0% 0%;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 190px;
  top: 10px;
}

.color-ball-relative {
  position: absolute;
  inset: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset;
  background: rgb(114, 161, 138) none repeat scroll 0% 0%;
  z-index: 2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: calc(40% - calc(#{$window-width} / 1000));
  top: 10px;
}
