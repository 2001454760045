.custom-calender-mui {
    display: grid;
    position: absolute;
}

.custom-calender-input-light {
    background-color: inherit;
}

.custom-calender-input-dark {
    background-color: #252929 !important;
    border: 1px solid #494444;
    color: white;
}

.custom-calender-mui-light {
    div:first-of-type {
        [class*="materialui-daterange-picker-MuiInput-underline"]{
            &::after{
                border-bottom: 1px solid #8A61D5;
            }
        }
        [class*="materialui-daterange-picker-makeStyles-dateRangePicker"] {
            div:first-of-type {
                div:first-of-type {
                    [class*="materialui-daterange-picker-MuiGrid-root"] {
                        div:nth-child(3) {
                            div {
                                div:first-of-type {
                                    div:nth-child(2) {
                                        div:first-of-type {
                                            [class*="materialui-daterange-picker-MuiPopover-root"] {
                                                div:nth-child(3) {
                                                    margin-top: 200px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    [class*="materialui-daterange-picker-makeStyles-filled"] {
                        background: #8A61D5;
                        border: 1px solid #8A61D5;
                    }
                    [class*="materialui-daterange-picker-makeStyles-filled"] {
                        background: #8A61D5;
                        border: 1px solid #8A61D5;
                    }
                    [class*="materialui-daterange-picker-makeStyles-outlined"] {
                        border: 1px solid #8A61D5;
                    }
                }
            }
        }
    }
}

.custom-calender-mui-dark {
    div:first-of-type {
        color: white;
        [class*="materialui-daterange-picker-MuiInput-underline"]{
            &::after{
                border-bottom: 1px solid #39255e;
            }
        }
        [class*="materialui-daterange-picker-makeStyles-dateRangePicker"] {
            div:first-of-type {
                div:first-of-type {
                    background-color: #252929;
                    [class*="materialui-daterange-picker-MuiGrid-root"] {
                        div:nth-child(3) {
                            div {
                                div:first-of-type {
                                    div:nth-child(2) {
                                        div:first-of-type {
                                            [class*="materialui-daterange-picker-MuiPopover-root"] {
                                                div:nth-child(3) {
                                                    margin-top: 200px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    [class*="materialui-daterange-picker-MuiPaper-root"] {
                        background: #212121;
                    }
                    [class*="materialui-daterange-picker-makeStyles-highlighted"] {
                        background: #212121;
                    }
                    [class*="materialui-daterange-picker-makeStyles-filled"] {
                        background: #39255e;
                        border: 1px solid #39255e;
                    }
                    [class*="materialui-daterange-picker-makeStyles-filled"] {
                        background: #39255e;
                        border: 1px solid #39255e;
                    }
                    [class*="materialui-daterange-picker-makeStyles-outlined"] {
                        border: 1px solid #39255e;
                    }
                    div {
                        color: white;
                        svg {
                            color: white;
                        }
                        span {
                            p {
                                color: white;
                            }
                            color: white;
                        }
                        div {
                            div {
                                button {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
